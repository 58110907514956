//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 例如(post)：
export function updateBook(obj) {
    return request({
        url: '/book/update',      // url = base url + request url
        method: 'post',
        data: obj,    //----------------->>>区别
    })
}
// 例如(get)：
export function getBookList(obj) {
    return request({
        url: '/book/list',      // url = base url + request url
        method: 'post',
        params: obj,    //----------------->>>区别
    })
}

// 登录：
export function LoginObj(obj) {
    return request({
        url: '/common/login',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 学生修改密码：
export function updatePasswordObj(obj) {
    return request({
        url: '/study/students/updatePassword',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 家长修改密码：
export function familysupdatePasswordObj(obj) {
    return request({
        url: '/study/familys/updatePassword',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 图形验证码：
export function CodeObj(randomStr) {
    //let randomStr = sessionStorage.getItem("RandomCode")  //获取session的四位随机码
    console.log(randomStr,'91')
    return request({
        url:  `/study/students/getCode/${randomStr}`,      // url = base url + request url
        method: 'get',
        //params: obj,
    })
}



// 学生登录：
export function stuLoginObj(obj) {
    return request({
        url: '/study/students/login',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 学生注册：
export function stuRegObj(obj) {
    return request({
        url: '/study/students/reg',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询省市列表：
export function findProviceCityObj(obj) {
    return request({
        url: '/search/parameters/findProviceCity',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 发送手机验证码：
export function sendPhoneCodeObj(obj) {
    return request({
        url: '/study/sms/sendPhoneCode',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询年级列表：
export function findScreenGradeObj(obj) {
    return request({
        url: '/search/parameters/findScreenGrade',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询学生信息：
export function getStuByUserNameObj(obj) {
    return request({
        url: '/study/students/getStuByUserName',   // url = base url + request url
        method: 'get',

    })
}
// 修改学生信息：
export function updatePersonObj(obj) {
    return request({
        url: '/study/students/updatePerson',   // url = base url + request url
        method: 'post',
        data: obj,

    })
}


// 家长登陆：
export function parLoginObj(obj) {
    return request({
        url: '/study/familys/login',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 家长注册：
export function parRegObj(obj) {
    return request({
        url: '/study/familys/reg',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询家长信息：
export function queryFamilyInfoObj(obj) {
    return request({
        url: '/study/familys/queryFamilyInfo',   // url = base url + request url
        method: 'get',

    })
}

// 修改家长信息：
export function updateFamilyObj(obj) {
    return request({
        url:  `/study/familys/updateFamily`,      // url = base url + request url
        method: 'post',
        data: obj,
    })
}

// 查询学生届别：
export function findGradeObj(obj) {
    return request({
        url: '/search/parameters/findGrade',   // url = base url + request url
        method: 'get',

    })
}

