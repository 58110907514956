<template>
  <span>
      <div class="layoutHeader-1" v-if="EducationCase==1&&stu==1">
          <div class="logo-header">
            <div class="content">
                <div class="head-login" @click="toCaseHome()"><img src="../../assets/images/head-logo.png" alt=""></div>
                <div class="head-infor">
                    <div class="left">
      <!--                <div class="ha01"> 余额：80学币</div>-->
                      <!--                <div class="ha02">充值</div>-->
                      <!--                <div class="ha03">订单</div>-->
                    </div>
                    <div class="right">
                      <div class="ha04">
<!--                          <div><i class="el-icon-message"></i></div> 消息-->
                      </div>
                      <div class="ha05">
                        <div><i class="el-icon-user-solid"></i></div>
                        <el-dropdown>
                            <span class="el-dropdown-link">
                              <div class="drop-1">{{userInfo.stuName}}</div>
                              <div class="drop-2">
                                <i class="el-icon-arrow-down"></i>
                              </div>


                            </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <div class="dcr-menu"><el-button type="text" @click="toRevise()">个人资料</el-button></div>
                              <div class="dcr-menu"><el-button type="text" @click="outLogin()">退出</el-button></div>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>

                    </div>
                  </div>
            </div>
          </div>
          <div class="nav-header" v-if="EducationCase ==1">
            <div class="content"></div>
          </div>
      </div>
      <div class="layoutHeader-1" v-if="EducationCase==2&&par==1">
         <div class="logo-header">
          <div class="content">
                <div class="head-login"  @click="toParCaseHome()"><img src="../../assets/images/head-logo.png" alt=""></div>
                <div class="head-infor">
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
          </div>
         </div>
       </div>
      <div class="layoutHeader-1" v-eles>
         <div class="logo-header">
          <div class="content">
                <div class="head-login" @click="tostuLogin()"><img src="../../assets/images/head-logo.png" alt=""></div>
                <div class="head-infor">
                  <div class="left"></div>
                  <div class="right"></div>
                </div>
          </div>
         </div>
       </div>

  </span>

</template>


<script>
import {mapState} from "vuex";

export default {
  name: '',
  data() {
    return {
      noreg:1,
      nowHome:0,
      userInfo:{

      },
      portraitList: [
        {id:1, url: require('../../assets/images/m1.png')},
        {id:2, url: require('../../assets/images/m2.png')},
      ],
      picImgSrc: {}

    }
  },
  computed:{
    ...mapState([
      'cartCount',
      'StuByUserData'
    ])
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.nowHome = this.$route.query.nowHome;
    this.EducationCase = this.$route.query.EducationCase;
    this.par = this.$route.query.par
    this.stu = this.$route.query.stu

  },
  methods: {
    toCaseHome() {
      this.$router.push({
        path: "/maina/caseHome",
        query: {
          stu:1,
          EducationCase:1,
          nowHome:1
        }
      });
    },
    toParCaseHome() {
        this.$router.push({
            path: "/maina/parentCaseHome",
            query: {
                nowHome:1,
                par:1
            }
        });
    },
    // 跳转到学生注册
    toStuRegister() {
      this.$router.push({
        path: "/register",
        query: {
          noreg:1
        }
      });
    },
    tostuLogin() {
      this.$router.push({
        path: "/stuLogin",
        query: {
        }
      });
    },
    outLogin() {
      this.$confirm('您将退出您的账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("status");
        localStorage.removeItem("Authorization");
        this.$router.replace({
          path: "/",
          meta: {allowBack: false}
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
      // MessageBox.$confirm("您将退出您的账号, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //  .then(() => {
      //       localStorage.removeItem("userInfo");
      //       localStorage.removeItem("status");
      //       localStorage.removeItem("Authorization");
      //       this.$router.replace({
      //         path: "/",
      //         meta: {allowBack: false}
      //       });
      //     })
      //     .catch(() => {
      //
      //  });
    }

  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.layoutHeader-1 {
  .logo-header {
    height: 70px;
    width: 100%;
    background: url("../../assets/images/header.png") no-repeat;
    background-size: 100% 70px;
    position:relative;
    .content {
      width: 98%;
      margin: auto;
      .head-login {
        width: 380px;
        height: 70px;
        cursor:pointer;
        img {
          height: 54px;
          margin-top: 8px;
        }
      }
      //flex两列布局
      .head-infor{
        width: 100%;
        margin-top: 24px;
        display: flex;
        .left {
          width:50%;
          display: flex;
          .ha01 {
            width: 150px;
            height: 24px;
            line-height: 24px;
            border-right: 1px solid #bbb;
            display: flex;
            div{
              margin-top: 3px;
              margin-right: 6px
            }
          }
          .ha02 {
            height: 24px;
            line-height: 24px;
            padding: 0 10px;
            color: #0060F1;
            border-right: 1px solid #bbb;
          }
          .ha03 {
            height: 24px;
            line-height: 24px;
            padding: 0 10px;
            color: #0060F1;
          }
        }
        .right {
          width: 50%;
          flex-grow: 0;
          display: flex;
          justify-content: flex-end;
          .ha04 {
            height: 24px;
            line-height: 24px;
            display: flex;
            div{
              margin-top: 3px;
              margin-right: 6px
            }
          }
          .ha05 {
            line-height: 24px;
            padding: 0 10px;
            color: #0060F1;
            display: flex;
            div{
              margin-top: 3px;
              margin-left: 5px;
              margin-right: 2px
            }
            .el-dropdown-link {
              display: flex;
              .drop-1 {

              }
              .drop-2 {
                margin-top: 0px;
              }
            }
          }
        }


      }
    }
  }
  .nav-header {
    width: 100%;
    height: 40px;
    background-color: #0090DA;
    .content {
      width: 98%;
      margin: auto;
    }
  }
}

.dcr-menu {
  width: 120px;
  box-sizing: border-box;
  padding-left: 10px;
}
.dcr-menu:hover {
  background-color: #EBF5FB;
}


</style>
