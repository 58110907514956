<template>
  <div class="caseM110-wrap">
    <div class="caseM110head">
      <header2></header2>
    </div>
    <div class="caseM110content">
      <div class="main-wrapA">
        <div class="content">
          <div class="regHd">
              <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini" plain round>返回</el-button></div>
              修改密码
          </div>

          <div class="Revise" >
            <div class="reg-warp">
              <div class="personal">
                  <el-form
                          ref="ruleForm"
                          :model="ruleForm"
                          status-icon
                          :rules="rules"
                          label-width="100px"
                  >
                      <el-form-item label="姓名" prop="">
                          {{ruleForm.stuName}}
                      </el-form-item>

                      <el-form-item label="手机号码" prop="">
                          {{ruleForm.mobileNumber}}
                      </el-form-item>

<!--                      <el-form-item label="验证码" prop="verifCode">-->
<!--                          <el-input v-model="ruleForm.verifCode" style="width: 327px;margin-right: 10px"></el-input>-->
<!--                          <button class="login-getcode" type="primary"  @click="getValCode" :disabled="disabled">{{codeTitle}}</button>-->
<!--                      </el-form-item>-->

                      <el-form-item label="设置密码" prop="pass">
                          <el-input v-model="ruleForm.pass" type="password" style="width: 450px"></el-input>
                      </el-form-item>
                      <el-form-item label="确认密码"  prop="passWord">
                          <el-input v-model="ruleForm.passWord" type="password" style="width: 450px"></el-input>
                      </el-form-item>
                  </el-form>
              </div>

            </div>
            <div class="btn-warp">
              <el-button type="primary" @click="regForm('ruleForm')">提 交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>


<script>
  import header2 from '@/components/layout/header2.vue'
  import { getStuByUserNameObj, updatePasswordObj,sendPhoneCodeObj,findScreenGradeObj } from '@/api/login'
  export default {
    name: '',
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.passWord !== '') {
            this.$refs.ruleForm.validateField('passWord');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        disabled:false,
        codeTitle:'获取验证码',
        selectedOptions2:[],
        findProviceCityData:[],
        findScreenGradeData:[],
        defaultData:{   //联级选择
          value: 'value',
          label: 'label',
          children: 'children'
        },
        userInfo:{
          userName:''
        },
        profile:'',
        picImgSrc: {},

        time:5,
        btntxt:"发送验证码",
        clickFlag:-1,
        cur:0 ,//默认选中第一个tab
        stateList: [
          {
            name: '男',
            id: 1
          },
          {
            name: '女',
            id: 2
          }
        ],
        ruleForm:{
          passWord: "",
          mobileNumber: "",
        },

        value: [],

        rules: {
          stuName: [
            {
              required: true,
              message: '请输入用户名',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 30,
              message: '用户名长度必须在 1 到 30个字符',
              trigger: 'blur',
            },
          ],
          nickName:[
            {
              required: true,
              message: '请输入昵称',
              trigger: 'blur',
            },
          ],

          pass: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
            { validator: validatePass, trigger: 'blur' }
          ],
          passWord:[
            { required: true, message: '请确认密码', trigger: 'blur' },
            { min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur', required: true }
          ],
          mobileNumber: [
            {
              required: true,
              message: '手机号码不能为空',
              trigger: 'blur',
            },
            {pattern: /^1[345678]\d{9}$/, message: '请输入正确是手机号'},
            {
              min: 1,
              max: 30,
              message: '用户名长度必须在 1 到 30个字符',
              trigger: 'blur',
            },
          ],


          code: [
            {
              required: true,
              message: '请输入图形验证码',
              trigger: 'blur',
            },
            {
              min: 4,
              max: 4,
              message: '验证码长度必须为4个字符',
              trigger: 'blur',
            },
          ],
        },
      }
    },


    created() {
      this.stu=this.$route.query.stu;
      this.ruleForm = JSON.parse(localStorage.getItem("userInfo"));
      this.profile = this.ruleForm.profilePhotoPath
      this.getStuByUser()
      this.portraitList.forEach((ele) => {
        if (this.ruleForm.profilePhotoPath == ele.id){
          this.picImgSrc = ele
        }
      })

    },
    mounted() {

    },
    methods: {
        //返回
        back() {
            this.$router.go(-1);
        },

      getStuByUser() {
        getStuByUserNameObj().then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            this.ruleForm = res.data
            this.ruleForm.area1= JSON.parse(res.data.area).map(it => Number(it))
            this.selectedOptions2 = this.ruleForm.area1
            let str= res.data.area
            //this.selectedOptions=JSON.stringify(str)
            //this.selectedOptions=str.split(":")
            this.selectedOptions=str.split(' ')
            console.log(this.selectedOptions,'111')
          }
        })

      },

       //获取验证码
        getValCode(){
            let obj = {
                mobile:this.ruleForm.mobileNumber,
                type:1// 学生
            }
            sendPhoneCodeObj(obj).then((res) => { // eslint-disable-line no-unused-vars
                if (res.code == 200) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    console.log(res,'发送手机验证码')
                    this.disabled = true;
                    this.codeTitle = "请稍候...";
                    setTimeout(() => {
                        this.doLoop(60)
                    }, 500)
                } else {
                    this.$message({
                        message: res.message,
                        type: 'warning'
                    });
                }
            })
        },
        //验证码倒计时
        doLoop: function(seconds) {
            let _this = this
            seconds = seconds ? seconds : 60;
            this.codeTitle = seconds + "s后获取";
            let countdown = setInterval(() => {
                if (seconds > 0) {
                    _this.codeTitle = seconds + "s后获取"
                    --seconds;
                } else {
                    _this.codeTitle = "获取验证码";
                    _this.disabled = false;
                    clearInterval(countdown);
                }
            }, 1000);
        },


      regForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
              let params = {
                  passWord:this.ruleForm.passWord,
                  mobileNumber:this.ruleForm.mobileNumber,
              }
              updatePasswordObj(params).then((res) => { // eslint-disable-line no-unused-vars
                  if(res.code == 200) {
                      this.$message({
                          message: res.message,
                          type: 'success'
                      });

                  }else {
                      this.$message({
                          message: res.message,
                          type: 'warning'
                      });
                  }
              })

          }
        })

      },




    },
    components: {
      // 图标
      header2

      //注册图标组件名称
    }
  }
</script>
<style lang="scss" scoped>
    .back-btn {
        border: 1px solid #c4e7ff;padding: 0px 10px; height:32px;line-height:32px; background-color: #37AEFF; color:#c4e7ff
    }
  .caseM110-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    overflow-y: hidden;
    .caseM110head{
      width: 100%;
      height: 70px;
      position: fixed;
      z-index: 0;
    }
    .caseM110content{
      width: 100%;
      position: fixed;
      /*固定定位*/
      top: 70px;
      bottom: 0;
      overflow-y: auto;
    }
    .main-wrapA {
      padding-top: 20px;
      .content {
          width: 98%;
          height: 820px;
          box-sizing: border-box;
      }
    }
  }
  .login-getcode {
      border: none;
      font-size: 14px;
      height: 38px;
      line-height: 38px;
      border-radius: 5px;
      color: #fff;
      box-sizing: border-box;
      padding: 0 10px;

      background-color: #008DF0;
  }
    .regHd {
        width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid #238bff;
        position: relative;
        .back {
            position: absolute;
            justify-content: flex-start;
            left: 0px;
        }
    }
  .tabs {
    width: 100%;
    height: 90px;
    font-size: 15px;
    font-weight: bold;
    display:flex;
    justify-content: center;
    align-items: center;
    .active {
      border-radius: 50px;
      border: 1px solid #F77134;
      background: #FFFCB2;
      color: #ff6820;
    }

    .li-tab {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      border: 1px solid #bbb;
      margin: 0px 25px 0 25px;
      display: inline-block;
      text-align: center;
      font-size: 14px;
      cursor: pointer;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50px;
      }

      .t1 {
        text-align: center;
      }
    }


  }
  .Revise {
    display: block;
    width: 100%;
    height: 80%;
    margin-top: 10px;

    .reg-warp {
      width: 90%;
      margin: auto;
      padding: 0 50px;
      box-sizing: border-box;
      overflow-y: auto;
      .test {
        margin-left: 30px;
        color: #b1bcd6;
      }
      .personal {
        width: 700px;
        margin: 30px auto;
        display: flex;
      }
      .account {
        width: 100%;
        display: flex;
        margin-top: 40px;
        .p-lf {
          height: 320px;
          display: flex;
          padding-right: 30px;
          align-items: center;
          text-align: center;
          border-right: 1px dashed #d5dee9;

          width: 20px;
          line-height: 24px;
          font-size: 16px;
          font-weight: bold;
        }
        .p-rg {
          width: 1200px;
          height: 320px;
          .portraitList {
            width: 100%;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              float: left;
            }
          }
          //background-color: #238bff;
        }
      }

      .parentpersonal {
        width: 100%;
        display: flex;
        margin-top: 20px;
        .p-lf {
          height: 190px;
          display: flex;
          padding-right: 30px;
          align-items: center;
          text-align: center;
          border-right: 1px dashed #d5dee9;

          width: 20px;
          line-height: 24px;
          font-size: 16px;
          font-weight: bold;
        }
        .p-rg {
          width: 1200px;
          height: 190px;
          .portraitList {
            width: 100%;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              float: left;
            }
          }
          //background-color: #238bff;
        }
      }
      .parentaccount {
        width: 100%;
        display: flex;
        margin-top: 40px;
        .p-lf {
          height: 230px;
          display: flex;
          padding-right: 30px;
          align-items: center;
          text-align: center;
          border-right: 1px dashed #d5dee9;

          width: 20px;
          line-height: 24px;
          font-size: 16px;
          font-weight: bold;
        }
        .p-rg {
          width: 1200px;
          height: 230px;
          .tip {
            width: 96%;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            margin: auto auto 10px auto;
            background: #FFECD8;
            border: 1px solid #FF9B36;
            opacity: 1;
            color: #D50007;
          }
          .portraitList {
            width: 100%;
            img {
              width: 50px;
              height: 50px;
              margin-right: 20px;
              float: left;
            }
          }
          //background-color: #238bff;
        }
      }
    }
    .btn-warp {
      width: 100%;
      height: 40px;
      margin-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }
  }
</style>
